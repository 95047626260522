import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

class VideoPlayer extends React.Component {
    componentDidMount() {
        // instantiate Video.js

        const playerOptions = {};
        playerOptions.fluid = this.props.fluid;
        playerOptions.width = this.props.width;
        playerOptions.height = this.props.height;
        playerOptions.controls = this.props.controls;
        playerOptions.autoplay = this.props.autoplay;
        playerOptions.preload = this.props.preload;
        playerOptions.bigPlayButton = this.props.bigPlayButton;

        this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
            console.log('onPlayerReady', this)
        });

        this.player.fill(this.props.fill);
        this.player.src(this.props.src);
        this.player.poster(this.props.poster);
    }

/*
    componentWillReceiveProps(newProps) {
        // When a user moves from one title to the next, the VideoPlayer component will not be unmounted,
        // instead its properties will be updated with the details of the new video. In this case,
        // we can update the src of the existing player with the new video URL.
        if (this.player) {
            console.log("Component will receive props");
            this.player.src({
                type: newProps.video.mime_type,
                src: newProps.video.video_url
            });
        }
    }
*/
    componentDidUpdate(newProps) {
        // When a user moves from one title to the next, the VideoPlayer component will not be unmounted,
        // instead its properties will be updated with the details of the new video. In this case,
        // we can update the src of the existing player with the new video URL.
        if (this.player) {
            console.log("Component did update");

            this.player.src(newProps.src);
            this.player.poster(newProps.poster);

        }
    }


    // destroy player on unmount
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        return (
            <div data-vjs-player className={`my-react-player`}>
                <video ref={node => this.videoNode = node} className={`video-js ${this.props.bigPlayButtonCentered? 'vjs-big-play-centered' : ''} ${this.props.className}`}></video>
            </div>
        )
    }
}

export default VideoPlayer;