import React, {Component} from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {Start} from "./components/Start";
import Player from "./components/Player";

class App extends Component {
    player = {}

    render() {
        return (

            <Router>
                <Switch>
                    <Route path="/" exact={true} component={Start}/>
                    <Route path="/:id/:p" component={Player}/>
                    <Route path="/:id" component={Player}/>
                </Switch>
            </Router>

        );
    }
}

export default App;
