import React, {Component} from 'react';
import './Playlist.css';
import {Link} from "react-router-dom";

class Playlist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playlist: props.playlist,
            listId: props.listId
        };
    }

    componentDidMount() {
    }

    render() {
        // let {isLoaded, items} = this.state;
        return (
            <>
                <h5>Kudy dál:</h5>
                {this.state.playlist.map(item => (
                        <div className="where-item" key={item.id}>
                            <img src={item.thumbnail} width={75} height={75} alt={item.title}/>
                            {item.title}{item.subtitle ? ` - ${item.subtitle}` : ''}<br/>
                            <Link to="#" onClick={() => window.location.href=`/${this.state.listId}/${item.id}`}>přehrát</Link>
                        </div>
                ))}
            </>
        )
    }
}

export default Playlist;