import VideoPlayer from "../VideoPlayer";
import Playlist from "./Playlist";
import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {Header} from "./Header";
import {Footer} from "./Footer";
import {NothingHere} from "./NothingHere";

class Player extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listId: this.props.match.params.id.toLowerCase(),
            playId: this.props.match.params.p,
            isLoaded: false,
            isError: false,
            playlist: [],
            nowPlaying: {}
        };

        console.log(props.match.params)
    }

    componentDidMount() {
        console.log("Fetching...");

        fetch(`../conf/${this.state.listId}.json`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    playlist: json.playlist,
                    nowPlaying: this.getNowPlaying(json.playlist),
                    isLoaded: true
                });
                console.log("Done.");
            })
            .catch(err => {
                this.setState( {
                    isError: true
                });
            })
    }

    getNowPlaying(varPlayList) {
        console.log(varPlayList);
        for (let i = 0; i < varPlayList.length; i++) {
            let item = varPlayList[i];

            if (item.id == this.state.playId) {
                console.log(item);
                return item;
            }
        }

        return varPlayList[0];
    }

    componentWillUpdate(newProps) {
        console.log("Player update...");
        console.log(newProps);
    }


    render() {
        if (this.state.isError) {
            return (
                <NothingHere/>
            )
        } else if (!this.state.isLoaded) {
            return (
                <div></div>
            )
        } else {
            return (
                <div className="application">
                    <Helmet>
                        <meta property="og:title"
                              content={`${this.state.nowPlaying.title} - ${this.state.nowPlaying.subtitle}`}/>
                        <meta property="og:type" content="video.movie"/>
                        <meta property="og:url" content="https://tovideo.cz/"/>
                        <meta property="og:image" content={this.state.nowPlaying.poster}/>
                        <title>{this.state.nowPlaying.title} - {this.state.nowPlaying.subtitle}</title>
                        <link rel="canonical" href="http://mysite.com/example"/>
                    </Helmet>

                    <section className="intro">
                        <a href="/" className="logo">ZVL</a>
                        <header className="center">
                            <Header/>
                        </header>

                        <div id="middle-box" className="main-container">

                            <main id="main-section" className="">
                                <h1 className="">{this.state.nowPlaying.title}</h1>
                                <h3 className="red">{this.state.nowPlaying.subtitle}</h3>
                                <div id="player-wrap">
                                    <VideoPlayer className="" src={this.state.nowPlaying.video} bigPlayButton={true}
                                                 bigPlayButtonCentered={true} controls={true} fluid={false}
                                                 fill={true} poster={this.state.nowPlaying.poster} preload={true}
                                    />
                                </div>
                            </main>
                            <aside className="">
                                <Playlist listId={this.state.listId} playlist={this.state.playlist} hide={this.state.playId}/>
                            </aside>

                        </div>

                        <footer className="center">
                            <Footer/>
                        </footer>

                    </section>
                </div>
            )
        }
    }
}

export default Player;